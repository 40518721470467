/* 
Some styles in the legacy styles and packages are affecting the iclib ui components
These are overrides to negate those legacy styles
*/

#portal-product-component a.page-link, 
#portal-product-component a.page-link:hover,
#portal-product-component a.page-link:active,
#portal-product-component a.page-link:visited,
#portal-product-component a.page-link:focus {
    position: initial;
    display: initial;
    padding: 0;
    margin: 0;
    line-height: inherit;
    background-color: inherit;
    border: none;
}

#portal-product-component label.styled-select-label {
    margin: 0;
}

#portal-product-component button:focus {
    outline: none;
}