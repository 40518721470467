.fancy-tab-container {
  padding: 10px 5px 0 5px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  background: #f8f9fa;
}

.fancy-tabs {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: -1px;
  animation: bounceIn 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tab-item {
  padding: 8px 14px;
  border-radius: 13px 13px 0 0;
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-bottom: none;
  margin-bottom: 0;
  cursor: pointer;
  transform-origin: center;
}

.tab-item:hover {
  z-index: 2;
  transform: translateY(-4px) scale(1.05);
  background: #ffffff;
  animation: wobble 0.8s ease-in-out;
  box-shadow: 0 10px 20px rgba(59, 130, 246, 0.3);
}

.tab-item.active {
  background: #3B82F6;
  border: 1px solid #3B82F6;
  border-bottom: 2px solid #3B82F6;
  margin-bottom: -1px;
  z-index: 1;
  animation: superActivate 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.5);
}

.tab-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  transition: 0.5s;
}

.tab-item:hover::before {
  left: 100%;
  animation: shimmer 1.5s infinite;
}

.tab-link {
  text-decoration: none;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.tab-item.active .tab-link {
  color: #ffffff;
  text-shadow: none;
}

.tab-link:hover {
  color: #3B82F6 ;
}

.custom-tab-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  display: block;
  margin-right: 8px;
  transition: all 0.3s ease;
  position: relative;
  animation: floatIcon 3s ease-in-out infinite;
}

.tab-item:hover .custom-tab-icon {
  /* transform: scale(1.2) rotate(15deg); */
  animation: spinIcon 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tab-item.active .custom-tab-icon {
  animation: superIconPop 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tab-icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
  position: relative;
}

.checkmark-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #22c55e;
  border-radius: 30%;
  padding: 2px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 2;
}

.checkmark-icon svg {
  width: 8px;
  height: 8px;
  color: white;
}

.tab-title {
  display: inline-block;
  line-height: 1;
}

@keyframes pulseGlow {
  0%, 50%, 100% {
    box-shadow: none;
    transform: none;
  }
}

.tab-item:hover:not(.active) {
  background: linear-gradient(45deg, #f8f8f8, #ffffff);
  animation: glowPulse 1.5s infinite;
}

.tab-item:not(.active) {
  background: #f0f0f0;
}

@keyframes wobble {
  0%, 100% { transform: translateY(-4px) scale(1.05) rotate(0deg); }
  25% { transform: translateY(-4px) scale(1.05) rotate(-2deg); }
  75% { transform: translateY(-4px) scale(1.05) rotate(2deg); }
}

/* @keyframes superActivate {
  0% { transform: scale(0.8) rotate(-3deg); }
  40% { transform: scale(1.2) rotate(5deg); }
  70% { transform: scale(0.9) rotate(-2deg); }
  100% { transform: scale(1) rotate(0deg); }
} */

/* @keyframes spinIcon {
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.5) rotate(180deg); }
  100% { transform: scale(1.2) rotate(360deg); }
}

@keyframes superIconPop {
  0% { transform: scale(0.8) rotate(0deg); }
  40% { transform: scale(1.5) rotate(180deg); }
  70% { transform: scale(1.2) rotate(270deg); }
  100% { transform: scale(1) rotate(360deg); }
} */

@keyframes shimmer {
  0% { left: -100%; }
  100% { left: 100%; }
}

/* @keyframes floatIcon {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
} */

@keyframes glowPulse {
  0% {
    box-shadow: 0 0 10px rgba(59, 130, 246, 0.3),
                0 0 20px rgba(59, 130, 246, 0.2),
                0 0 30px rgba(59, 130, 246, 0.1);
  }
  50% {
    box-shadow: 0 0 20px rgba(59, 130, 246, 0.5),
                0 0 40px rgba(59, 130, 246, 0.3),
                0 0 60px rgba(59, 130, 246, 0.2);
  }
  100% {
    box-shadow: 0 0 10px rgba(59, 130, 246, 0.3),
                0 0 20px rgba(59, 130, 246, 0.2),
                0 0 30px rgba(59, 130, 246, 0.1);
  }
}

/* @keyframes bounceIn {
  0% {
    opacity: 0;
    transform: translateY(-200px) scale(0.3);
  }
  50% {
    opacity: 0.8;
    transform: translateY(10px) scale(1.1);
  }
  70% {
    opacity: 0.9;
    transform: translateY(-10px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
} */

.tab-item:last-child .tab-link {
  transition: all 0.3s ease;
}

.tab-item:last-child .tab-link:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
}

.tab-item:last-child .tab-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.security-tabs-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.security-dropdown {
  position: relative;
}

.security-dropdown-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #3B82F6;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.security-dropdown-button:hover {
  background: #2563EB;
}

.security-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 50;
  min-width: 200px;
}

.security-dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.security-dropdown-item:hover {
  background: #F3F4F6;
}

.security-dropdown-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.security-dropdown-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* CIS Level specific styles - Updated with stronger highlight styles */
.tab-item[data-tab="cislevel1"],
.tab-item[data-tab="cislevel2"] {
  background: #f0f0f0;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}

.tab-item[data-tab="cislevel1"].active,
.tab-item[data-tab="cislevel2"].active {
  background: #3B82F6 !important;
  border: 1px solid #3B82F6 !important;
  border-bottom: 2px solid #3B82F6 !important;
  color: white !important;
  z-index: 2;
  box-shadow: 0 4px 15px rgba(59, 130, 246, 0.4);
}

/* Ensure text is white when tab is active */
.tab-item[data-tab="cislevel1"].active .tab-link,
.tab-item[data-tab="cislevel2"].active .tab-link,
.tab-item[data-tab="cislevel1"].active .tab-title,
.tab-item[data-tab="cislevel2"].active .tab-title {
  color: white !important;
}

/* Add a subtle glow effect when active */
.tab-item[data-tab="cislevel1"].active::after,
.tab-item[data-tab="cislevel2"].active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.2), transparent);
  pointer-events: none;
  border-radius: 13px 13px 0 0;
}

.tab-item[data-tab="cislevel1"] .custom-tab-icon,
.tab-item[data-tab="cislevel2"] .custom-tab-icon {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  object-fit: contain;
}

.tab-item[data-tab="cislevel1"]:hover,
.tab-item[data-tab="cislevel2"]:hover {
  transform: translateY(-4px) scale(1.05);
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(59, 130, 246, 0.3);
}

.tab-item[data-tab="cislevel1"].active .tab-link,
.tab-item[data-tab="cislevel2"].active .tab-link {
  color: #ffffff;
}

.tab-item[data-tab="cislevel1"] .tab-link:hover,
.tab-item[data-tab="cislevel2"] .tab-link:hover {
  color: #3B82F6;
}

/* CIS Level checkmark styles */
.tab-item[data-tab="cislevel1"] .checkmark-icon,
.tab-item[data-tab="cislevel2"] .checkmark-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #22c55e;
  border-radius: 30%;
  padding: 2px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 2;
}


.tab-item.active .custom-tab-icon[src="/compliant-2.svg"] {
  filter: brightness(0) invert(1);
}

/* Ensure SVG stays white when tab is active and hovered */
.tab-item.active:hover .custom-tab-icon[src="/compliant-2.svg"] {
  filter: brightness(0) invert(1);
}

/* Change SVG color to white when tab is active */
.tab-item.active .custom-tab-icon[src="/cyber-security.svg"] {
  filter: brightness(0) invert(1);
}

/* Ensure SVG stays white when tab is active and hovered */
.tab-item.active:hover .custom-tab-icon[src="/cyber-security.svg"] {
  filter: brightness(0) invert(1);
}

/* Mobile-specific styles */
@media screen and (max-width: 480px) {
  .fancy-tab-container {
    padding: 8px 2px 0 2px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE/Edge */
  }

  .fancy-tab-container::-webkit-scrollbar {
    display: none; /* Chrome/Safari */
  }

  .fancy-tabs {
    flex-wrap: nowrap;
    gap: 2px;
    padding-bottom: 8px;
    min-width: min-content;
  }

  .tab-item {
    padding: 6px 10px;
    min-width: max-content;
    border-radius: 10px 10px 0 0;
    transform: none !important;
  }

  .tab-item:hover {
    transform: none !important;
    box-shadow: none;
  }

  .tab-link {
    font-size: 12px;
    white-space: nowrap;
  }

  .custom-tab-icon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 6px;
  }

  .tab-title {
    font-size: 12px !important;
  }

  /* Disable certain animations on mobile */
  .tab-item:hover::before,
  .tab-item.active::after {
    display: none;
  }

  .tab-item.active {
    box-shadow: none;
  }
}