.display-none-timeout {
    display: none!important;
}
#timeout-warning-container-outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000091;
}
#timeout-warning-container-inner {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    padding: 30px;
    margin: 10px;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 2px 2px;
}
#timeout-warning-container-inner > p {
    margin-bottom: 25px;
    text-align: center;
}
#timeout-warning-container-inner > div {
    display: flex;
}
#timeout-warning-container-inner > div > button {
    background-color: #038fde;
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 0 8px;
    font-size: 16px;
    padding: 5px 10px;
    transition: transform ease-in-out .3s;
}
#timeout-warning-container-inner > div > button:hover {
    cursor: pointer;
    transform: scale(1.05);
}
@media (min-width: 600px) {
    #timeout-warning-container-inner {
        width: 480px;
    }
}