.free-trial-expired {
    align-items: center;
    display: flex;
    height: 95vh;
    justify-content: center;
    width: 100%;
}

.free-trial-expired > div {
    margin: 0 10px;
    max-width: 1250px;
}

.free-trial-expired-title {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

.free-trial-expired-title > img {
    margin-right: 10px;
    width: 35px;
}

.free-trial-expired h1 {
    font-size: 28px;
    margin-bottom: 0;
}

.free-trial-expired-highlighted-text-block {
    border-left: 4px solid dodgerblue;
    border-radius: 0 4px 4px 0;
    padding: 10px 15px;
    box-shadow: 1px 1px 4px #a6a6a6;
    margin-bottom: 25px;
}

.free-trial-expired-text-block {
    margin: 15px;
}

.free-trial-expired p {
    font-size: 16px;
    line-height: 1.4;
}

.free-trial-expired a {
    font-weight: bold;
}

.free-trial-expired-ultrabold {
    font-weight: 700;
}

.free-trial-expired button {
    margin-top: 10px;
    margin-left: 15px;
}