.no-wrap {
    flex-wrap: nowrap;
}

.cloud-account-img-container {
    text-align: center;
    min-width: 40px;
}
.cloud-account-img-container > img {
    height: 14px;
}

.cloud-account-select {
    min-width: 190px;
    max-width: 320px;
}
.cloud-account-select > div > div {
    padding-right: 10px;
}

.topbar-button {
    color: inherit;
    cursor: pointer;
    background-color: white;
    border: 1px solid #d9d9d9;
    padding: 6px 14px;
    border-radius: 6px;
    font-size: 14px;
    transition: all .15s ease;
}
.topbar-button:hover {
    border: 1px solid #009ef6;
    color: #009ef6;
}
.topbar-button > i {
    margin-right: 5px;
}

@media (max-width: 450px) {
    .cloud-account-select {
        width: 190px;
    }
}
@media (max-width: 520px) {
    #topbar-accounts-monitored {
        display: none;
    }
    .cloud-account-select > div > div {
        padding-right: 0;
    }
}

.user-profile-topbar {
  margin-top: 20px !important;
}