.systemfailure-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgb(253, 253, 253);
	padding: 0 100px;
      }
      
      .systemfailure-container h2 {
	color: red ;
	font-size: 40px;
      }
      
      .systemfailure-container h3 {
	color: red;
	font-size: 20px;
	text-align: center;
      }
      
      