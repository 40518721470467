
#root {
    height: 100%;
    overflow:auto
}
.error-text {
    color: rgb(187, 0, 0);
}
.disabled-link {
    pointer-events: none;
}
.validate-outer {
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.validate-inner {
    padding: 35px 50px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 8px 0px #ababab;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.validate-inner > h1 {
    text-align: center;
    margin-bottom: 30px;
}
.validate-inner > form {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.validate-inner > form > label {
    margin-bottom: 0;
    margin-right: 6px;
}
.validate-inner > form > input, 
.validate-inner > form > button {
    border: 2px solid grey;
    padding: 5px 8px;
}
.validate-inner > form > input {
    width: 300px;
    border-radius: 5px 0 0 5px;
}
.validate-inner > form > button {
    border-radius: 0 5px 5px 0;
    border-width: 2px 2px 2px 0;
    background-color: #0079ef;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    transition: background-color .2s ease-in-out;
    width: 58px;
}
.validate-inner > form > button:hover {
    background-color: #0063c7;
    cursor: pointer;
}
.validate-inner > form > button > i {
    animation: validate-loading-spinner .8s linear 0s infinite forwards;
}
@keyframes validate-loading-spinner {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}
.guide-container {
    border-radius: 5px;
    padding: 35px 50px;
    margin: 40px auto;
    background: #fff;
    width: 80%;
}
.guide-container hr {
    border-color: grey!important;
    margin: 25px 0;
}
.top-page-title {
    font-size: 1.5rem;
    margin-bottom: 30px;
}
.card-container p {
    margin: 0;
    font-size: 16px;
    line-height: 1.4;
}
.card-container > .ant-tabs-card .ant-tabs-content {
    height: 100%;
    margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 20px 15px;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 30px;
}
.fa-windows, .fa-apple, .fa-download {
    margin-right: 6px;
}
.ant-tree-child-tree > li:first-child {
    padding-top: 1px;
}
.ant-tree li {
    padding: 1px 0;
}
.guide-ol {
    padding-left: 10px;
}
.guide-ol > li {
    margin: 18px 0;
}
.guide-ol > li::marker {
    font-weight: bold;
}
.guide-img-box {
    margin: 50px 0;
}
.guide-img-box > img {
    width: 50%;
    max-width: 450px;
    min-width: 250px;
}
.code-block {
    background-color: #eaecef;
    border-radius: 5px;
}
.code-block-type-fill {
    width: 100%;
}
.code-block-type-fill > pre {
    clear: both;
    padding: 20px;
    padding-top: 0;
}
.code-block-type-fill > pre::-webkit-scrollbar-track {
    background: rgb(207, 207, 207);
    border-radius: 20px;
}
.code-block-type-fill > pre::-webkit-scrollbar-thumb {
    background-color: rgb(136, 136, 136);
    border-radius: 20px;
}
.code-block-type-sm {
    width: min-content;
    margin: 10px 0;
}
.code-block-type-sm > pre {
    padding: 8px 15px;
}
.code-block-type-inline {
    padding: 0 5px;
    height: 20px;
    margin: 0 4px;
    width: min-content;
}
.code-block-type-inline-outer {
    display: flex;
}
.code-block-type-fill > button {
    float: right;
    border: 2px solid grey;
    border-radius: 5px;
    padding: 4px 10px;
    background-color: #fff;
    margin: 10px;
    transition: background-color .2s ease-in-out;
}
.code-block-type-fill > button:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}
.install-button {
    padding: 10px 20px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid #0079ef;
    color: #0079ef;
    transition: background-color .15s ease-in-out, color .15s ease-in-out;
}
.install-button:hover {
    cursor: pointer;
    color: #fff;
    background-color: #0079ef;
}

@media only screen and (max-width: 900px) {
    .guide-container {
        width: 90%;
    }
    .center-page-title {
        font-size: 1.3rem;
    }
}
@media only screen and (max-width: 600px) {
    .validate-outer {
        height: fit-content;
    }
    .validate-inner {
        width: 100%;
        padding: 80px 30px;
        border-radius: 0; 
    }
    .validate-inner > form {
        flex-direction: column;
    }
    .validate-inner > form > label {
        align-self: baseline;
    }
    .validate-inner > form > input {
        width: 100%;
        border-radius: 5px;
    }
    .validate-inner > form > button {
        border-radius: 5px;
        width: 100%;
        margin-top: 8px;
    }
    .guide-container {
        width: 100%;
        border-radius: 0;
        padding: 35px 15px;
        margin: 0;
    }
    .center-page-title {
        font-size: 1.2rem;
    }
}