input.pw {
  font-family: text-security-disc;
}
input.pw::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Ubuntu", sans-serif;
}
input.pw::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Ubuntu", sans-serif;
}
input.pw::-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Ubuntu", sans-serif;
}
input.pw::-moz-placeholder {
  /* Firefox 18- */
  font-family: "Ubuntu", sans-serif;
}
