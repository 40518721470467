#deviceManagementSubMenu .ant-menu-submenu-title {
  margin-left: 14px !important
}


.ant-layout-sider .ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 92px !important
}

#deviceManagementSubMenu .ant-menu-item {
  padding-left: 103px !important
}


.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: unset !important;
}

.active-main-title {
  color: #038fde !important;
}

/* .ant-layout-sider-children {
  height: 100%;
  overflow: auto;
} */
/* .ant-layout-sider .ant-menu-inline, .ant-layout-sider .ant-menu-vertical, .ant-layout-sider .ant-menu-vertical-left, .gx-drawer-sidebar .ant-menu-inline, .gx-drawer-sidebar .ant-menu-vertical, .gx-drawer-sidebar .ant-menu-vertical-left{
  height:100% !important;
  overflow:auto
} */
.ant-drawer-wrapper-body {
  overflow: hidden
}

.ant-drawer-content {
  overflow: hidden
}

.sidebar ::-webkit-scrollbar {
  width: 6px;
  /* This is more usable for users trying to click it. */
  background-color: rgb(249, 249, 249);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

.sidebar ::-webkit-scrollbar-thumb {
  width: 6px;
  /* This is more usable for users trying to click it. */
  background-color: rgb(213, 213, 213);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

/* Style for active submenu items */
.ant-menu-item-selected {
  color: #1890ff !important;
}

/* Style for active submenu item links */
.ant-menu-item-selected a {
  color: #1890ff !important;
}

/* Style for active submenu item spans */
.ant-menu-item-selected .submenu-unlocked-link-color,
.ant-menu-item-selected .unlocked-link-color {
  color: #1890ff !important;
}

/* Hover state for submenu items */
.ant-menu-item:hover .submenu-unlocked-link-color,
.ant-menu-item:hover .unlocked-link-color,
.ant-menu-item:hover a {
  color: #1890ff !important;
}

/* Style for active nested submenu items */
.ant-menu-submenu .ant-menu-item-selected {
  color: #1890ff !important;
  background-color: #e6f7ff !important;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.1) !important;
  transform: translateX(-4px) !important;
  border-right: 3px solid #1890ff !important;
}

/* Keep the blue color even when the item is both active and hovered */
.ant-menu-item-selected:hover .submenu-unlocked-link-color,
.ant-menu-item-selected:hover .unlocked-link-color,
.ant-menu-item-selected:hover a {
  color: #1890ff !important;
}

/* Style for the submenu item text */
.ant-menu-item .submenu-unlocked-link-color,
.ant-menu-item .unlocked-link-color {
  transition: color 0.3s ease;
}

/* Style for selected menu items */
.ant-menu-item-selected {
  font-weight: 600 !important;
  background-color: #e6f7ff !important;
}

/* Keep the bold weight when hovering over selected items */
.ant-menu-item-selected:hover {
  font-weight: 600 !important;
}

/* Style for selected submenu items */
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  font-weight: 600 !important;
  color: #1890ff !important;
  background-color: transparent !important;
}

/* Ensure text color and weight persist on selection */
.ant-menu-item-selected .submenu-unlocked-link-color,
.ant-menu-item-selected .unlocked-link-color,
.ant-menu-item-selected a {
  color: #1890ff !important;
  font-weight: 600 !important;
}

/* Override default hover state when menu is open but no item is selected */
.ant-menu-submenu-open:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title {
  background-color: transparent !important;
  color: inherit !important;
  font-weight: normal !important;
}

/* Only show highlight when item is actually selected */
.ant-menu-submenu.ant-menu-submenu-open > .ant-menu:not(.ant-menu-selected) .ant-menu-item:not(.ant-menu-item-selected) {
  background-color: transparent !important;
  color: inherit !important;
  font-weight: normal !important;
}

/* Remove highlight from submenu title when just hovering */
.ant-menu-submenu:hover > .ant-menu-submenu-title {
  background-color: transparent !important;
}

/* Only show highlight when submenu is selected, not just open */
.ant-menu-submenu:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title {
  background-color: transparent !important;
}

/* Keep text color consistent for non-selected submenu titles */
.ant-menu-submenu:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title .submenu-unlocked-link-color {
  color: #232323 !important;
}

/* Only show blue color when actually selected */
.ant-menu-submenu-selected > .ant-menu-submenu-title .submenu-unlocked-link-color {
  color: #1890ff !important;
}

/* Style for selected menu items - enhanced */
.ant-menu-item-selected {
  font-weight: 600 !important;
  background-color: #e6f7ff !important;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.1) !important;
  transform: translateX(-4px) !important;
  transition: all 0.3s ease !important;
  border-right: 3px solid #1890ff !important;
}

/* Style for active nested submenu items - enhanced */
.ant-menu-submenu .ant-menu-item-selected {
  color: #1890ff !important;
  background-color: #e6f7ff !important;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.1) !important;
  transform: translateX(-4px) !important;
  border-right: 3px solid #1890ff !important;
}

/* Hover effect for selected items */
.ant-menu-item-selected:hover {
  background-color: #f0f9ff !important;
  font-weight: 600 !important;
  transform: translateX(-6px) !important;
}

/* Style for selected submenu items - enhanced */
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  font-weight: 600 !important;
  color: #1890ff !important;
  background-color: transparent !important;
}

/* Ensure text color and weight persist on selection with enhanced styling */
.ant-menu-item-selected .submenu-unlocked-link-color,
.ant-menu-item-selected .unlocked-link-color,
.ant-menu-item-selected a {
  color: #1890ff !important;
  font-weight: 600 !important;
  text-shadow: 0 0 1px rgba(24, 144, 255, 0.1) !important;
}

/* Keep submenu background transparent */
.ant-menu-submenu > .ant-menu {
  background-color: transparent !important;
}

/* Ensure submenu items have transparent background when not selected */
.ant-menu-submenu .ant-menu-item:not(.ant-menu-item-selected) {
  background-color: transparent !important;
}

/* Keep submenu title background transparent even when active */
.ant-menu-submenu-active > .ant-menu-submenu-title {
  background-color: transparent !important;
}