/* Mobile Responsiveness */
@media screen and (max-width: 640px) {
  /* Main container adjustments */
  .flex-col.items-center.justify-start {
    padding: 16px 12px;
  }

  /* Header adjustments */
  .text-3xl {
    font-size: 24px;
    line-height: 1.2;
  }

  .text-lg {
    font-size: 16px;
    line-height: 1.4;
  }

  /* Cloud onboarding section */
  .flex.items-center.gap-6 {
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  /* Button adjustments */
  .inline-flex.items-center.justify-center {
    width: 100%;
    height: auto;
    padding: 12px;
    margin: 4px 0;
  }

  /* Hide arrow icons between buttons on mobile */
  .w-6.h-6.text-blue-500.mx-2 {
    display: none;
  }

  /* Adjust cloud provider buttons */
  .w-48, .w-64, .w-60 {
    width: 100% !important;
  }

  /* Coming soon label adjustment */
  [class*="absolute right-"] {
    right: 8px;
    top: -8px;
  }

  /* Personnel onboarding section adjustments */
  .flex.flex-col.items-center.justify-center.w-full.gap-6 {
    width: 100%;
  }

  /* Bottom compliance module section */
  .flex.items-center.justify-center.w-full.p-6 {
    margin: 16px 12px;
    width: calc(100% - 24px);
  }

  /* Background overlay adjustment */
  .absolute.inset-0.bg-white\/80 {
    backdrop-filter: blur(4px);
  }

  /* Logo size adjustment */
  .h-10.inline-block.mx-1 {
    height: 32px;
  }

  /* Icon size adjustments */
  .w-7.h-7, .w-6.h-6, .w-5.h-5 {
    width: 20px;
    height: 20px;
  }

  /* Button hover effects optimization for touch */
  .hover\:scale-105:hover {
    transform: none;
  }

  .hover\:translate-x-1:hover {
    transform: none;
  }

  /* Active/pressed state for better mobile feedback */
  .active\:scale-95:active {
    transform: scale(0.95);
  }

  /* Improve spacing between sections */
  .gap-6 {
    gap: 16px;
  }

  /* Adjust top padding */
  [class*="pt-[100px]"] {
    padding-top: 60px;
  }

  /* Header adjustments for mobile */
  .flex.items-center.gap-3.mb-2 {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  /* Title text and logo */
  .text-3xl.font-semibold.text-center {
    font-size: 22px;
    line-height: 1.3;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }

  /* Logo sizing */
  .h-10.inline-block.mx-1 {
    height: 28px;
    margin: 0 4px;
    vertical-align: middle;
  }

  /* Subtitle adjustment */
  .text-gray-600.text-lg.text-center {
    font-size: 16px;
    line-height: 1.4;
    padding: 0 16px;
    margin-top: 8px;
  }

  /* Section divider */
  .flex.flex-col.items-center.justify-center.w-full.gap-6 > div:nth-child(2) {
    border-top: 1px solid #e5e7eb;
    margin-top: 16px;
    padding-top: 16px;
    width: 100%;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 360px) {
  .text-3xl {
    font-size: 20px;
  }

  .text-lg {
    font-size: 14px;
  }

  .p-6 {
    padding: 12px;
  }

  .text-3xl.font-semibold.text-center {
    font-size: 20px;
  }
} 