.blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.foreground {
  position: absolute; /* Overlap the previous div */
  top: 0;
  width: 80%;
  height: 100%;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}
.imgtext {
  color: white;
  background: rgba(0, 0, 0, 0.4);
  width: 155px;
  height: 135px;
  padding: -50px -15px 0 -15px;
  opacity: 0;
  position: relative;
  bottom: -20px;
  filter: blur(2px);
  -webkit-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.locked-addon-pic {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 120px)
}


.pic {
  position: relative;
  overflow: hidden;

}
.button {
  width: 60px;
  height: 25px;
  background: DodgerBlue;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.blur {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.imgtext:hover {
  -webkit-opacity: 40;
  opacity: 40;
}
