@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

/* base styles */
.severity-message {
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 1px 1px;
    padding: 15px 18px;
}

.severity-message-title-row {
    align-items: center;
    display: flex;
}

.severity-message-title {
    margin: 0;
    margin-left: 5px;
}

.severity-title-icon {
    font-size: 18px;
    margin-right: 6px;
}

.severity-message-body {
    font-size: 14px;
    line-height: 1.4;
    margin-top: 10px;
}

/* note styles */
.note-message {
    background: #fff;
    border: none;
}

/* info styles */
.info-message {
    background: rgb(239, 247, 255);
    border: 1px solid rgb(66, 161, 255);
}
.info-message .severity-title-icon {
    color: dodgerblue;
}

/* warning styles */
.warning-message {
    background: #FFFBE6;
    border: 1px solid #FFE58F;
}
.warning-message .severity-title-icon {
    color: orange;
}

/* error styles */
.error-message {
    background: rgb(255, 239, 239);
    border: 1px solid rgb(255, 91, 91);
}
.error-message .severity-title-icon {
    color: #ff3232;
}